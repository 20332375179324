import React from "react";
import Home from "./pages/Home";
import Videos from "./pages/Videos";
import UfcEvent from "./pages/UfcEventFights";
import NewLookV1 from "./pages/NewLook_v1";
import NotFound from "./pages/NotFound";
import MainMenu from "./components/MainMenu";
import { Routes, BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <MainMenu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/ufc/event" element={<UfcEvent />} />
          <Route path="/newlook" element={<NewLookV1 />} />
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
