import React from "react";
import PropTypes from "prop-types";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { BsFillPlayCircleFill } from "react-icons/bs";
import parse from 'html-react-parser';
import { format } from 'date-fns';
import { useTranslation } from "react-i18next";

const YoutubeVideoInfo = ({ title, description, videoId, publishTime, thumbnail }) => {
  const { t } = useTranslation();
  let publishDate = format(new Date(publishTime), 'MM/dd/yyyy hh:mm a');
  let isShortOrPodcast = title.charAt(0) != "#" && !title.includes("La Casa Del Dragón episodio") && !description.includes("siguenos en youtube")

  return (
		<>
      <div className="container-fluid youtube-video-card">
        <Card className="container-sm mb-2">
          <Card.Img variant="top" src={thumbnail.url} />
          <Card.Body>
            <Card.Title>{parse(title)}{isShortOrPodcast ? " - (short or podcast) " : " "}<span className="publishedDate">{publishDate}</span></Card.Title>
            <Card.Text>
              <Button variant="primary" href={`https://youtu.be/${videoId}`} target="_blank"><BsFillPlayCircleFill /> {t("watch.video")}</Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

YoutubeVideoInfo.propTypes = {
    title: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    publishTime: PropTypes.string,
    thumbnail: PropTypes.object
  };

  export default YoutubeVideoInfo;