import React, { useState } from "react";
import Pagination from 'react-bootstrap/Pagination';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import PropTypes from "prop-types";

const Paging = ({ totalPages, currentPage, totalRecords, setCurrentPage }) => {
  let pages = [];

  for (let i = 1; i < totalPages + 1; i++) {
    if (totalPages > 5) {
      pages.push(<Pagination.Ellipsis key={`videosPageEllipsis${i}`} />);
    } else {
      pages.push(<Pagination.Item key={`videosPage${i}`} active={currentPage == i} onClick={() => setCurrentPage(i) } >{i}</Pagination.Item>);
    }
  }

  const goPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(--currentPage);
    }
  }

  const goNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(++currentPage);
    }
  }

  return (
	<>
  <div className="container-fluid pagination-container">
    <div className="pages-display">
      <Pagination>
        <Pagination.First disabled={currentPage == 1} onClick={() => setCurrentPage(1) } />
        <Pagination.Prev disabled={currentPage == 1} onClick={() => goPreviousPage() } />
        {pages}
        <Pagination.Next disabled={currentPage == totalPages} onClick={() => goNextPage() } />
        <Pagination.Last disabled={currentPage == totalPages} onClick={() => setCurrentPage(totalPages) } />
      </Pagination>
    </div>
      {
        totalRecords != undefined
        && <div className="pages-display"><Button variant="secondary" className="button-totalvideos">Total: <Badge bg="primary">{totalRecords}</Badge></Button></div>
      }
    </div>
    </>
  );
};

Paging.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalRecords: PropTypes.number,
    setCurrentPage: PropTypes.func.isRequired
  };

  export default Paging;