import React from "react";
import { useTranslation } from "react-i18next";
import Dropdown from 'react-bootstrap/Dropdown';

function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const handleSelect = (eventkey) => {
    i18n.changeLanguage(eventkey);
  }
  return (
    <>
    <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle variant="success" id="dropdown-language">
        {t("website-language")}
        </Dropdown.Toggle>
        <Dropdown.Menu>
            <Dropdown.Item eventKey="en">
                English
            </Dropdown.Item>
            <Dropdown.Item eventKey="es">
                Español
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
  </>
  );
}

export default LanguageSwitcher;