 // Install dependencies: npm install react-responsive-carousel
// Add styling: npm install styled-components

import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import podcastArtwork from '../images/Podcast_Artwork-SquidGame.png';

const Container = styled.div`
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background: #1a1a1a;
  color: white;
  padding: 10px 20px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Nav = styled.nav`
  display: flex;
  gap: 15px;

  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: #ff9900;
    }
  }
`;

const Hero = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: #f9f9f9;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain; /* Ensures the image is fully visible */
  }
`;

const Blurb = styled.div`
  flex: 1;
  margin-left: 2rem;

  h1 {
    font-size: 2.5rem;
    color: #333;
  }

  p {
    font-size: 1.2rem;
    color: #555;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 1rem;
  }
`;

const Section = styled.section`
  padding: 2rem;
  text-align: center;
  background: #f0f0f0;
`;

const CarouselSection = styled.section`
  padding: 20px;
  background: #f8f8f8;
`;

const HostSection = styled.section`
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background: #ffffff;
`;

const HostCard = styled.div`
  text-align: center;
  max-width: 250px;
  img {
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
`;

const LinksSection = styled.section`
  text-align: center;
  padding: 20px;
  background: #1a1a1a;
  color: white;

  a {
    text-decoration: none;
    color: #ff9900;
    margin: 0 10px;
  }
`;

const Footer = styled.footer`
  padding: 10px;
  background: #333333;
  color: white;
  text-align: center;
  a {
    color: #ff9900;
    text-decoration: none;
    margin: 0 10px;
  }
`;

function NewLookV1() {
  return (
    <Container>
      <Header>
        <h1>12Coffees Podcast</h1>
        <Nav>
          <a href="#">Home</a>
          <a href="#hosts">Hosts</a>
          <a href="#episodes">Episodes</a>
          <a href="#links">Links</a>
        </Nav>
      </Header>

      <Hero id="home">
        <ImageContainer>
          <img src={podcastArtwork} alt="Podcast Art" />
        </ImageContainer>
        <Blurb>
          <h1>Welcome to 12Coffees Podcast</h1>
          <p>
            Join us for caffeine-fueled discussions about House of the Dragon,
            UFC analysis, and everything in between. Hosted by three brothers
            who love coffee and epic conversations.
          </p>
        </Blurb>
      </Hero>

      <CarouselSection id="episodes">
        <Carousel showThumbs={false} autoPlay infiniteLoop>
          <div>
            <img src={podcastArtwork} alt="Episode 1" />
            <p className="legend">Episode 1: Topic Title</p>
          </div>
          <div>
            <img src="./episode2-thumbnail.jpg" alt="Episode 2" />
            <p className="legend">Episode 2: Topic Title</p>
          </div>
        </Carousel>
      </CarouselSection>
        
      <HostSection id="hosts">
        <HostCard>
          <img src="./host1.jpg" alt="Host 1" />
          <h3>Host 1</h3>
          <p>Description about Host 1.</p>
        </HostCard>
        <HostCard>
          <img src="./host2.jpg" alt="Host 2" />
          <h3>Host 2</h3>
          <p>Description about Host 2.</p>
        </HostCard>
        <HostCard>
          <img src="./host3.jpg" alt="Host 3" />
          <h3>Host 3</h3>
          <p>Description about Host 3.</p>
        </HostCard>
      </HostSection>

      <LinksSection id="links">
        <h2>Find Us On</h2>
        <a href="#">Spotify</a>
        <a href="#">Apple Podcasts</a>
        <a href="#">YouTube</a>
      </LinksSection>

      <Footer>
        <p>&copy; 2024 12Coffees Podcast</p>
        <a href="#">Privacy Policy</a>
        <a href="#">Terms of Service</a>
      </Footer>
    </Container>
  );
}

export default NewLookV1;
