import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosGet } from "../common/axiosService"; 
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import YoutubeEmbed from "../components/YoutubeEmbed";
import PodcastPlatforms from "../components/PodcastPlatforms";
import HostCard from "../components/HostCard";
import { YOUTUBE_CHANNEL_NAME } from "../common/constants";
import announcement from '../images/YouTube_banner.png';
import twelvecoffeesboard from '../images/12Coffees_blackboard_small.jpg';
import podcastArtwork from '../images/Podcast_Artwork-SquidGame.png';
import '../css/Home.css';

const maxResultsFromAPI = 5;
var fetchUrl = `/info/youtube/${YOUTUBE_CHANNEL_NAME}?maxResults=${maxResultsFromAPI}`;
var fetchHostsInfo = `/info/hosts`;

function Home() {
  const { t, i18n } = useTranslation();
  const [latestVideo, setLatestVideo] = useState(null);
  const [hostsInfo, setHostsInfo] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
      const fetchLatestVideo = async () => {
        try {
          const response = await axiosGet(fetchUrl);
          const latest = response.items.find(
            (v) => !v.snippet.title.includes("#short")
          );

          setLatestVideo(latest.contentDetails.videoId);
        } catch (error) {
          console.error("Failed to fetch latest video:", error);
        }
      };
    
      const fetchHostsInformation = async () => {
        try {
          const response = await axiosGet(fetchHostsInfo);
          setHostsInfo(response.values);
        } catch (error) {
          console.error("Failed to fetch hosts info:", error);
        } finally {
          setIsLoading(false);
        }
      };
    
      fetchLatestVideo();
      fetchHostsInformation();
  }, []);

  return (
    <div className="home">
      <Hero id="hero">
        <ImageContainer>
          <img src={podcastArtwork} alt="Podcast Art" />
        </ImageContainer>
        <Blurb>
          <div className="hero-blurb">
            <h1>{t("welcome_blurb")}</h1>
            <p>{t("hero_blurb")}</p>
          </div>
        </Blurb>
      </Hero>

      {/* https://react-bootstrap.github.io/components/carousel/ */}
      <Carousel className="home-carousel">
        <Carousel.Item interval={5000}>
          <div className="home-carousel-item">
            <img
              src={twelvecoffeesboard}
              alt="Merch slide"
            />
          </div>
          <Carousel.Caption>
            <h5>&#128073;{t("merch_store")}&#127978;</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={6000}>
          <div className="home-carousel-item">
            <div className="home-video">
              <YoutubeEmbed embedId={latestVideo} t={t} />
            </div>
          </div>
          <Carousel.Caption>
            <h5>{t("watch.latest_video")}</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <div className="home-carousel-item">
            <img
              src={announcement}
              alt="Choose your team!"
            />
          </div>
          <Carousel.Caption className="video-coming-soon-caption">
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <Card className="card-podcast">
        <Card.Header as="h3">Escucha el podcast en:</Card.Header>
        <Card.Body>
          <PodcastPlatforms />
          <CardGroup>
              <Card className="card-podcast-group">
                {/* iframe player code provided in buzzsprout */}
                <iframe id="player_iframe" src="https://www.buzzsprout.com/2377686?client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F2377686%2Fpodcast%2Fembed" 
                  loading="lazy"
                  width="100%"
                  height="375"
                  title="La Casa del Dragón: 12Coffees Podcast">
                </iframe>
              </Card>
              <Card className="card-podcast-group small-card">
                <iframe
                  src="https://www.tiktok.com/embed/7450916101329915166"
                  allowfullscreen
                  width="100%"
                  height="100%"
                  allow="encrypted-media;">
                </iframe>
              </Card>
            </CardGroup>
        </Card.Body>
      </Card>

      {isLoading ? (
        <span className="visually-hidden">{t("loading")}...</span>
        ) : (
        hostsInfo &&
        (
          <Card className="card-hosts">
            <Card.Header as="h3">Hosts</Card.Header>
            <Card.Body>
              <Card.Title className="card-hosts-title">Single Origin and Blends</Card.Title>
                <CardGroup>
                  {hostsInfo.map((host) =>(
                    <HostCard hostInfo={host} language={i18n.language} key={`${host[0]}_host_card`}/>
                  ))}
                </CardGroup>
            </Card.Body>
          </Card>
        )
      )}
    </div>
  );
}

// styled-components styles
const Hero = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: transparent;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 3;
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain; /* Ensures the image is fully visible */
  }
`;

const Blurb = styled.div`
  flex: 1;
  margin-left: 2rem;

  h1 {
    font-size: 2.5rem;
    color: #333;
  }

  p {
    font-size: 1.2rem;
    color: #555;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 1rem;
  }
`;

export default Home;
