import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { axiosPost } from "../common/axiosService";
import { parseEventResponse } from "../types/ufcEventTypes";
import "../css/Home.css";
import "../css/UfcEventFights.css";

var fetchHostsInfo = `/ufc/fights`;

function UfcEvent() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ufcStatsUrl, setUfcStatsUrl] = useState("");
  const [ufcEventInfo, setUfcEventInfo] = useState(null);
  const [redFighters, setRedFighters] = useState("");
  const [blueFighters, setBlueFighters] = useState("");

  const handleParse = async () => {
    if (!ufcStatsUrl) {
      setError("Please enter a valid UFC Stats URL.");
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      const bodyData = {
        url: ufcStatsUrl,
      };

      const response = await axiosPost(fetchHostsInfo, bodyData);
      const details = parseEventResponse(response);
      setUfcEventInfo(details);

      // Update redFighters and blueFighters based on the parsed response
      setRedFighters(details.fights.map((fight) => fight.fighter1).join(", "));
      setBlueFighters(details.fights.map((fight) => fight.fighter2).join(", "));
    } catch (error) {
      console.error("Failed to fetch UFC event fights:", error);
      setError("Failed to fetch UFC event fights. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  return (
    <div className="home">
      <h1>{t("UFC Event")}</h1>

      <div style={{ marginBottom: "1rem" }}>
        <label htmlFor="url-input">Enter UFCStats URL:</label>
        <input
          type="text"
          id="url-input"
          value={ufcStatsUrl}
          onChange={(e) => setUfcStatsUrl(e.target.value)}
          placeholder="http://ufcstats.com/event-details/..."
          style={{
            width: "100%",
            padding: "0.5rem",
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            fontSize: "1rem",
          }}
        />
        <button onClick={handleParse} disabled={isLoading} style={{ padding: "0.5rem 1rem", fontSize: "1rem" }}>
          {isLoading ? "Parsing..." : "Parse"}
        </button>
      </div>

      {/* Display Error Message */}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Display Parsed Event Info */}
      {ufcEventInfo && (
        <div style={{ marginTop: "1rem" }}>
          <h2>{ufcEventInfo.eventTitle}</h2>
          <p>Date: {ufcEventInfo.eventDate}</p>
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {ufcEventInfo.fights.map((fight, index) => (
              <li key={index} style={{ marginBottom: "0.25rem" }}>
                {fight.fighter1} vs. {fight.fighter2}
              </li>
            ))}
          </ul>

          <div
            style={{
              marginTop: "1rem",
              backgroundColor: "rgba(255, 255, 255, 0.5)", // White with 50% transparency
              borderRadius: "8px",
            }}
          >
            <p>
              <strong>Fighters (red):</strong> {redFighters}
              <button
                onClick={() => copyToClipboard(redFighters)}
                style={{
                  marginLeft: "0.5rem",
                  padding: "0.2rem 0.5rem",
                  cursor: "pointer",
                }}
              >
                Copy
              </button>
            </p>
            <p>
              <strong>Fighters (blue):</strong> {blueFighters}
              <button
                onClick={() => copyToClipboard(blueFighters)}
                style={{
                  marginLeft: "0.5rem",
                  padding: "0.2rem 0.5rem",
                  cursor: "pointer",
                }}
              >
                Copy
              </button>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default UfcEvent;