import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaCoffee } from "react-icons/fa";
import SocialMedia from "./SocialMedia";
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from "react-i18next";
import twelvecoffeesicon from '../images/12Coffees_200x200.png';
import "../css/MainMenu.css";

function MainMenu() {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const overrideToggle = (expanded) => {
    console.log("TOGGLE TRIGGERED...");
    setExpanded(prevExpanded => !prevExpanded);
  }

  return (
    <>
      <Navbar bg="12coffees" expand="md" expanded={expanded} onToggle={overrideToggle}>
        <Container>
          <Navbar.Brand as="label" className="top-menu">
            <img className='twelveCoffeesIcon' src={twelvecoffeesicon}></img>
            12 Coffees
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className='menu-icon-mobile'><FaCoffee size={30} /></Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto navbar-items">
              <Nav.Link href="home">{t("menu.home")}</Nav.Link>
              <Nav.Link href="videos">Videos</Nav.Link>
              <NavDropdown title={t("menu.drinks")} id="basic-nav-dropdown" as="label">
                <NavDropdown.Item href={`https://buymeacoffee.com/12coffees?l=${i18n.language}`} target="_blank">{t("menu.merch")}</NavDropdown.Item>
              </NavDropdown>
              <Nav><SocialMedia language={i18n.language} /></Nav>
            </Nav>
          </Navbar.Collapse>
          {!expanded 
            ?
            <Nav><LanguageSwitcher /></Nav>
            : ""
          }
          
        </Container>
      </Navbar>
    </>
  );
}

export default MainMenu;