import React from "react";
import { FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import buymeacoffeeicon from '../images/bmc-logo.png';

export default function SocialMedia({language}) {
  return (
    <div className="social-media-container">
      <a href="https://www.youtube.com/channel/UCLFwDFyXqBvExRpl-HyY9vA"
        className="youtube social"
        target="_blank">
        <FaYoutube size={30} />
      </a>
      <a href="https://www.instagram.com/12coffees"
        className="instagram social"
        target="_blank">
        <FaInstagram size={30} />
      </a>
      <a href="https://www.x.com/12Coffees"
        className="twitter social"
        target="_blank">
        <FaXTwitter size={30} />
      </a>
      <a href="https://www.tiktok.com/@12coffees"
        className="tiktok social"
        target="_blank">
        <FaTiktok size={30} />
      </a>
      <a href={`https://buymeacoffee.com/12coffees?l=${language}`}>
        <img className="buymeacoffee" src={buymeacoffeeicon} alt="Buy me a coffee!" />
      </a>
    </div>
  );
}