import React from "react";
import underconstruction from '../images/under_construction.png';

function NotFound() {
  return (
    <>
        <div className="app">
            <div className="underConstruction">
                <img src={underconstruction} alt="Under Construction" />
                <p>Under Construction! coming soon..</p>
            </div>
        </div>
    </>
  );
}

export default NotFound;
