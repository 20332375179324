// Define the structure for a single fight
export interface Fight {
    fighter1: string;
    fighter2: string;
    weightClass: string;
  }
  
  // Define the structure for the event
  export interface UfcEventDetails {
    eventTitle: string;
    eventDate: string;
    fights: Fight[];
  }
  
  // Function to parse the API response into UfcEventDetails format
  export const parseEventResponse = (response: any): UfcEventDetails => {
    return {
      eventTitle: response.event_title,
      eventDate: response.event_date,
      fights: response.fights.map((fight: string[]) => ({
        fighter1: fight[0],
        fighter2: fight[1],
        weightClass: fight[2],
      })),
    };
  };