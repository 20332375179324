import axios from "axios";
import axiosRetry from "axios-retry";
import { PRODUCCIONES_CHANGO_API_URL } from "../common/constants";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: PRODUCCIONES_CHANGO_API_URL,
  timeout: 5000, // 5-second timeout
  headers: {
    "Content-Type": "application/json",
  },
});

// Enable retries with axios-retry
axiosRetry(axiosInstance, {
  retries: 3, // Retry up to 3 times
  retryDelay: (retryCount) => retryCount * 1000, // Exponential backoff
  retryCondition: (error) => {
    // Retry on network errors or 5xx HTTP responses
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response?.status >= 500;
  },
});

// Centralized Axios Methods
export const axiosGet = async (url, params = {}) => {
  try {
    const response = await axiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    console.error(`GET request failed for ${url}:`, error);
    throw error; // Re-throw the error for further handling
  }
};

export const axiosPost = async (url, data = {}, params = {}) => {
  try {
    const response = await axiosInstance.post(url, data, { params });
    return response.data;
  } catch (error) {
    console.error(`POST request failed for ${url}:`, error);
    throw error;
  }
};

export const axiosPut = async (url, data = {}, params = {}) => {
  try {
    const response = await axiosInstance.put(url, data, { params });
    return response.data;
  } catch (error) {
    console.error(`PUT request failed for ${url}:`, error);
    throw error;
  }
};

export const axiosDelete = async (url, params = {}) => {
  try {
    const response = await axiosInstance.delete(url, { params });
    return response.data;
  } catch (error) {
    console.error(`DELETE request failed for ${url}:`, error);
    throw error;
  }
};