import React, { useEffect, useState } from "react";
import '../css/Home.css';
import '../css/Videos.css';
import YoutubeVideoInfo from "../components/YoutubeVideoInfo";
import Paging from "../components/Paging";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from "react-i18next";
import { PRODUCCIONES_CHANGO_API_URL, YOUTUBE_CHANNEL_NAME } from "../common/constants";

const pageSize = 10;
const excludeShorts = true;
const maxResultsFromAPI = 50;
const columnsPerRow = 2;
const pageToken = null;
var fetchUrl = `${PRODUCCIONES_CHANGO_API_URL}/info/youtube/${YOUTUBE_CHANNEL_NAME}?maxResults=${maxResultsFromAPI}`;

// see comment below inside useEffect
//  `https://www.googleapis.com/youtube/v3/search?part=snippet,id&channelId=${channelId}&key=${YouTubeAPI}&type=video&order=date&maxResults=${maxResultsFromAPI}`;

function Videos() {
  const { t } = useTranslation();
  const [allVideos, setAllVideos] = useState([]);
  const [latestVideo, setLatestVideo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    /*
    // fetch('./data/youtube.json', // if the real api call fails (code below) then use this with hardcoded data from a json file in /data
    //   {
    //       headers : { 
    //           'Content-Type': 'application/json',
    //           'Accept': 'application/json'
    //       }
    //   })
    //   .then(function(response){
    //     return response.json();
    //   })
    //   .then(function(jsonResponse) {
    */
    fetch(fetchUrl).then((response) => response.json()).then((jsonResponse ) => {
        let allVideos = jsonResponse.items.map(doc => ({
          ...doc
        }));

        var videos = excludeShorts ? allVideos.filter(v => (v.snippet.title.charAt(0) == "#" || !v.snippet.title.includes("#short"))) : allVideos;
        setAllVideos(videos);
        setLatestVideo(videos[0].contentDetails.videoId);
        let totalPages = videos != undefined ? Math.ceil(videos.length / pageSize) : 0
        setTotalPages(totalPages);
        setTotalRecords(videos.length);
      });
  }, []);

  useEffect(() => {
    if (allVideos.length > 0) {
        let indexOfLastRecord = currentPage * pageSize;
        let indexOfFirstRecord = indexOfLastRecord - pageSize;
        let currentRecords = allVideos != undefined ? allVideos.slice(indexOfFirstRecord, indexOfLastRecord) : 0;
        setCurrentRecords(currentRecords);
    }
  }, [allVideos, currentPage]);

  const getColumnsForRow = () => {
    let items = currentRecords.map((item) => {
      return (
          <Col key={`col${item.etag}`}>
            <YoutubeVideoInfo
              key={item.etag}
              title={item.snippet.title}
              description={item.snippet.description}
              videoId={item.contentDetails.videoId}
              publishTime={item.snippet.publishedAt}
              thumbnail={item.snippet.thumbnails.default} />
          </Col>
        )
      })

    return items;
  }

  return (
    <div className="home">
        <Container key="videosContainerColRow" className="videosContainerColRow" fluid>
          { totalPages > 0 &&
                  <Row xs={1} md={columnsPerRow} >
                    {getColumnsForRow()}
                  </Row>
          }
          { totalPages > 0 &&
              <Paging totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} totalRecords={totalRecords} />
          }
          { totalPages == 0 &&
              <div className="loading">
                <Spinner animation="grow" variant="warning">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span>{t("loading")}...</span>
              </div>
          }
        </Container>
      </div>
  );
}

export default Videos;
