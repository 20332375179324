import React from "react";
import PropTypes from "prop-types";
import Spinner from 'react-bootstrap/Spinner';

const YoutubeEmbed = ({ embedId, t }) => {
  return (
    embedId != null
    ?
		<>
      <div className="video-responsive">
        <iframe
          src={`https://www.youtube.com/embed/${embedId}`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="12Coffees youtube"
        />
      </div>
    </>
    : <>
        <div className="loading">
          <Spinner animation="grow" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <span>{t("loading")}...</span>
        </div>
      </>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string
};

export default YoutubeEmbed;