import React from "react";
import PropTypes from "prop-types";
import Card from 'react-bootstrap/Card';

const HostCard = ({ hostInfo, language }) => {
    let indexBio = language == "es" || language === undefined ? 1 : 2;
    let bio = hostInfo[indexBio];
    let name = hostInfo[0];
    let socialHandle = hostInfo[3];
    let picture = `${name}.png`;

    return (
	<>
        <Card className="card-host-single">
            <div className="img-wrapper">
                <Card.Img variant="top" src={`../images/${picture}`} className="card-image" />
            </div>
            <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>{bio}</Card.Text>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">{socialHandle}</small>
            </Card.Footer>
        </Card>
    </>
    );
};

HostCard.propTypes = {
    hostInfo: PropTypes.array,
    language: PropTypes.string
  };

  export default HostCard;