import React from "react";
import Card from 'react-bootstrap/Card';
import applepodcast from '../images/ApplePodcast-standard.png';
import spotifylogo from '../images/Spotify Logo.png';
import amazonmusic from '../images/AmazonMusicLogo.png';
import deezerlogo from '../images/DeezerLogo.png';
import iheartradiologo from '../images/iHeartRadioLogo.png';
import listennoteeslogo from '../images/ListenNotesLogo.png';
import playerfmlogo from '../images/PlayerFMLogo.png';
import pocketcastlogo from '../images/PocketCastsLogo.png';
import podcastaddictlogo from '../images/PodcastAddictLogo.png';
import podcastaindexlogo from '../images/PodcastIndexLogo.jpg';
import podchaserlogo from '../images/PodchaserLogo.png';
import truefanslogo from '../images/TruefansLogo.png';
import ivooxlogo from '../images/ivooxLogo.png';

const PodcastPlatforms = () => {
  return (
		<>
            <Card.Title>
            <a href="https://podcasts.apple.com/us/podcast/12coffees-hotd-podcast/id1752881448" className="podcast_player" target="_blank">
              <img src={applepodcast} alt="App Icon Apple Podcasts" className="podcast_player_image" />
            </a>
            <a href="https://open.spotify.com/show/6K5yispqrhBxC6Zaggbt3G" className="podcast_player" target="_blank">
              <img src={spotifylogo} alt="App Icon Spotify" className="podcast_player_image" />
            </a>
            <a href="https://music.amazon.com/podcasts/c1524ecd-7e7f-4655-be32-61859659a839/12coffees-hotd-podcast" className="podcast_player" target="_blank">
              <img src={amazonmusic} alt="App Icon Amazon Music" className="podcast_player_image" />
            </a>
            <a href="https://iheart.com/podcast/187199199" className="podcast_player" target="_blank">
              <img src={iheartradiologo} alt="App Icon iHeartRadio" className="podcast_player_image" />
            </a>
            <a href="https://podcastindex.org/podcast/6942593" className="podcast_player" target="_blank">
              <img src={podcastaindexlogo} alt="App Icon Podcast Index" className="podcast_player_image" />
            </a>
            <a href="https://podcastaddict.com/podcast/12coffees-podcast/5184184" className="podcast_player" target="_blank">
              <img src={podcastaddictlogo} alt="App Icon Podcast Addict" className="podcast_player_image" />
            </a>
            <a href="https://www.podchaser.com/podcasts/12coffees-podcast-5768824" className="podcast_player" target="_blank">
              <img src={podchaserlogo} alt="App Icon Podchaser" className="podcast_player_image" />
            </a>
            <a href="https://www.deezer.com/show/1001025002" className="podcast_player" target="_blank">
              <img src={deezerlogo} alt="App Icon Deezer" className="podcast_player_image" />
            </a>
            <a href="https://player.fm/series/series-3580635" className="podcast_player" target="_blank">
              <img src={playerfmlogo} alt="App Icon Player FM" className="podcast_player_image" />
            </a>
            <a href="https://podfans.fm/05e1a387-34bc-5f88-9b94-76ec90c18b73" className="podcast_player" target="_blank">
              <img src={truefanslogo} alt="App Icon TrueFans" className="podcast_player_image" />
            </a>
            <a href="https://www.listennotes.com/c/0b6def719b2a46bbafc161103f4d1d31/" className="podcast_player" target="_blank">
              <img src={listennoteeslogo} alt="App Icon Listen Notes" className="podcast_player_image" />
            </a>
            <a href="https://pca.st/0zpjtj6f" className="podcast_player" target="_blank">
              <img src={pocketcastlogo} alt="App Icon PocketCast" className="podcast_player_image" />
            </a>
            <a href="https://go.ivoox.com/sq/2449764" className="podcast_player" target="_blank">
              <img src={ivooxlogo} alt="ivoox" className="podcast_player_image" />
            </a>
          </Card.Title>
        </>
  );
};

export default PodcastPlatforms;